<template>
  <div class="page">
    <div v-if="lang() == 'zh'">
      <h3 class="text-c">
        <strong>2020中国国际工业博览会（ CIIF）和数字展厅活动-隐私声明</strong>
      </h3>
      <p>
        <strong>1.</strong> <em><strong>*前言*</strong></em>
      </p>
      <p>
        本隐私声明（以下简称“声明”）适用于ABB集团，即瑞士ABB集团和瑞士ABB集团直接或间接控股、拥有或取得控制权的公司实体。与您保持联系的ABB公司（以下简称“ABB”或“我们”）将根据本声明所述之目的处理您的个人数据信息，且有权决定如何使用这些数据。
      </p>

      <p>
        ABB非常尊重您的个人数据隐私权。本声明阐述了我们为什么要收集您的个人数据、如何使用您的个人数据、如何处理这些数据以及您对个人数据拥有哪些权利。
      </p>
      <p>
        <em><strong>*2.*</strong></em> <strong>谁来处理您的个人数据</strong>
      </p>
      <p>
        ABB集团及其子公司负责处理您的个人数据。根据个人隐私和数据保护相关法律规定，正在为您提供服务（如组织活动）或与您保持联系的ABB子公司是您的个人数据主要控制方。
      </p>

      <p>
        上海ABB工程有限公司（地址：中国上海浦东新区康新公路4528号，邮编：201319）是您的个人数据“控制方”，该公司将对您的数据资料负责。
      </p>
      <p>
        <em><strong>*3.*</strong></em> <strong>我们收集和使用哪些信息？</strong>
      </p>
      <p>
        我们将收集并使用与虚拟活动有关的个人数据。我们可能收集的个人数据类别包括：
      </p>

      <p>
        §
        <strong>您在活动过程中向我们提供的用于注册或交流的联系信息，</strong
        >如名字和姓氏（包括您的虚拟帐户显示的姓名）、公司电子邮箱地址、公司联系电话、您的助手姓名和电子邮箱地址、IP地址、设备标识码（如移动设备ID/电脑主机
        ID、跟踪分析数据源码、Cookie等）。
      </p>
      <p>§ <strong>您向我们提供的其他信息，</strong>如：职位、职业或职称等。</p>

      <p>
        <em><strong>*4.*</strong></em>
        <strong>我们为何要使用您的个人数据？</strong>
      </p>

      <p>您的上述个人数据可能用于以下所述之目的：</p>
      <p>§ 登记参加活动和论坛；</p>
      <p>§ 在专用应用程序中为您创建个人帐户；</p>
      <p>§ 为您提供活动相关信息和服务；</p>
      <p>§ 提供个人活动体验服务；</p>
      <p>§ 推进虚拟活动并为您提供满意的服务；</p>
      <p>§ 开展并推进活动满意度调查；</p>
      <p>
        §
        创建媒体资产（照片、视频和录音）以展示活动效果，并将这些资产存入我们的品牌数据库；
      </p>
      <p>§ 为虚拟活动的相关文章和出版物提供活动内容；以及</p>
      <p>§ 在ABB平台、第三方平台和社交媒体上发布虚拟活动的现场图片。</p>

      <p>
        我们将只针对以上所述之目的收集您的个人数据信息。在进行数据统计、服务优化和IT系统测试时，我们会尽量合理使用匿名信息。因此，这些信息不会直接（或间接）表明您的身份或凸显您的个人身份。
      </p>

      <p>
        <em><strong>*5. 数据处理的法律依据*</strong></em>
      </p>
      <p>我们将在您同意的基础上根据本隐私声明所述之目处理您的个人数据。</p>
      <p>
        <em><strong>*6.*</strong></em>
        <strong
          >我们将与（欧盟及非欧盟、欧洲经济区及非欧洲经济区以及控制您个人数据信息的ABB公司所在国以外的）其他方共享您的个人数据</strong
        >
      </p>
      <p>
        ABB只会根据如下所述之目的与ABB其他分支机构或第三方共享您的个人数据。当与ABB分支机构或第三方共享您的个人数据时，我们会将您的个人数据转移至欧盟和欧洲经济区以外或控制您个人数据的ABB公司所在国以外的国家，或者这些国家/地区将有权访问你的信息。我们会采取充分的数据安全保护措施来保护您的个人数据，包括欧盟委员会的充分性决定（点击此处查看更多）、标准合同条款（点击此处查看更多）、隐私盾认证（点击此处查看更多），以及我们的一些服务供应商已采用的具有约束力的公司规则（点击此处查看更多）。此外，我们已采取其他措施，将数据从欧盟、欧洲经济区和控制您个人数据的ABB公司所在国以外的国家转出，以保护您的个人信息安全。想要了解当前的数据安全保护措施，请登陆<a
          href="http://www.abb.com/privacy"
          >www.abb.com/privacy</a
        >发送客户请求。
      </p>
      <figure>
        <table>
          <thead>
            <tr>
              <th>
                <strong>数据接收方名称或-（用于非欧盟国家）-接收方类型</strong>
              </th>
              <th><strong>数据*</strong>*接收方所在地区**</th>
              <th><strong>接收目的</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>ABB分支机构和子公司</td>
              <td>见ABB子公司名单</td>
              <td>见本隐私声明所述之目的</td>
            </tr>
            <tr>
              <td>ABB业务合作伙伴、经销商和代理商</td>
              <td>欧盟/欧洲经济区和非欧盟/欧洲经济区（全球范围内）</td>
              <td>见本隐私声明所述之目的</td>
            </tr>
            <tr>
              <td>服务供应商</td>
              <td>欧盟/欧洲经济区和非欧盟/欧洲经济区（全球范围内）</td>
              <td>
                与ABB授权的IT服务供应商、营销机构、支付服务供应商、客户支持供应商共享用户数据
              </td>
            </tr>
            <tr>
              <td>ABB业务或资产的潜在收购方或实际收购方</td>
              <td>欧盟/欧洲经济区和非欧盟/欧洲经济区（全球范围内）</td>
              <td>用于业务或资产评估，或用于本隐私声明中所述之目的</td>
            </tr>
            <tr>
              <td>
                根据相关法规或法律程序要求，需要向执法部门或政府机构等提交相关信息的接收方
              </td>
              <td>欧盟/欧洲经济区和非欧盟/欧洲经济区（全球范围内）</td>
              <td>
                接收方需要根据相关法律规定、政府机构的合法要求或有效的法律要求提供个人数据
              </td>
            </tr>
          </tbody>
        </table>
      </figure>
      <p>
        <em><strong>*7. 您的个人数据保留时间*</strong></em>
      </p>
      <p>
        根据强制性法律规定，ABB必须为某些个人数据设置最短的保留时间。ABB只会根据本隐私声明所述之目的保留您的个人数据。一般情况下，ABB会出于活动需要保存用户的个人数据，除非当地法律另有规定，要求我们将您的个人数据保存更长时间。同时，根据数据保护的相关法律规定，我们不得以任何可识别形式保存用户的个人数据，且保存时间不得超过处理个人数据所需的时间。我们将设置IT应用程序和策略，确保ABB在不再需要您的个人数据时将其删除。
      </p>

      <p>
        欲了解更多关于个人数据的特定保留时间，可登陆<a
          href="http://www.abb.com/privacy"
          target="_blank"
          class="url"
          >www.abb.com/privacy</a
        >发送用户请求。
      </p>
      <p>
        <em><strong>*8.*</strong></em> <strong>您的数据隐私权</strong>
      </p>

      <p>
        根据您所在的司法管辖区以及处理您个人数据的司法管辖区，您将拥有以下权利：
      </p>

      <figure>
        <table>
          <thead>
            <tr>
              <th><strong>数据隐私权</strong></th>
              <th><strong>权利说明</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>获取数据的权利</td>
              <td>
                您有权向ABB了解您的个人数据收集情况，或获得ABB掌握的有关您个人数据信息的副本。
              </td>
            </tr>
            <tr>
              <td>更正数据的权利</td>
              <td>
                您有权要求ABB立即更正ABB掌握的关于您的不正确或不完整的个人数据信息。
              </td>
            </tr>
            <tr>
              <td>删除数据的权利</td>
              <td>
                如果ABB不再需要您的个人数据或根据相关法律规定必须删除用户数据又或者对该数据进行处理不合法时，您有权要求ABB删除您的个人数据。
              </td>
            </tr>
            <tr>
              <td>限制个人数据处理的权利</td>
              <td>您有权在特定情况下限制ABB对您个人数据进行处理。</td>
            </tr>
            <tr>
              <td>数据可携带权</td>
              <td>
                您有权根据自身需求以结构化、计算机可读格式接收个人数据，或要求ABB与第三方共享您的个人数据。
              </td>
            </tr>
            <tr>
              <td>反对数据处理的权利</td>
              <td>
                如果ABB认为维护您的数据隐私权胜过自身合法权益，则您有权反对ABB在符合自身合法权益的基础上处理您的个人数据。
              </td>
            </tr>
          </tbody>
        </table>
      </figure>

      <p>
        请注意，上述权利不是绝对的，我们无法确保始终满足您的要求。例如，如果根据法律义务或合同义务ABB有必要保留某些个人数据信息时，我们不能删除您的个人数据或同意限制对您个人数据的处理。
      </p>

      <p>
        您可以登陆<a
          href="http://www.abb.com/privacy"
          target="_blank"
          class="url"
          >www.abb.com/privacy</a
        >，要求行使数据隐私权。
      </p>
      <p>
        <em><strong>*联系方式及更多信息*</strong></em>
      </p>
      <p>
        如果您想访问个人数据信息、行使以上述任何其他权利或者对ABB如何处理您的个人数据有任何疑问或疑虑，请发送邮件至<a
          href="mailto:privacy@abb.com"
          target="_blank"
          class="url"
          >privacy@abb.com</a
        >与ABB集团数据保护官联系，或登陆<a
          href="http://www.abb.com/privacy提交投诉"
          >www.abb.com/privacy提交投诉</a
        >。
      </p>

      <p>
        如果您对我们的答复不满意或认为我们正在违法处理您的个人数据信息，您有权向您居住地或工作地所在国家的数据隐私管理局进行投诉，或者针对您认为ABB违反数据隐私法的行为向法院提起诉讼。
      </p>

      <p>
        <strong
          >如果您同意我们将您的个人数据用于本隐私声明所述之目的，请在以下方框中打勾。您也可以随时登陆<a
            href="http://www.abb.com/privacy"
            target="_blank"
            class="url"
            >www.abb.com/privacy</a
          >填写相关表格撤回知情同意。</strong
        >
      </p>
      <p>
        本人同意ABB处理本人注册参加2020中国国际工业博览会和数字展厅活动的相关个人数据。
      </p>
    </div>

    <div v-else>
      <p>
        <em
          ><strong
            >*CIIF 2020 &amp; Digital show room - Privacy Notice*</strong
          ></em
        >
      </p>
      <p>
        <em><strong>*1. Introduction*</strong></em>
      </p>
      <p>
        This Privacy Notice (&quot;Notice&quot;) applies to the ABB Group of
        companies, which means ABB Ltd, Switzerland and each entity in which ABB
        Ltd, Switzerland, directly or indirectly, has a majority holding or owns
        or controls the majority of voting rights. The ABB company that is
        communicating with you (referred to as &quot;ABB&quot; or
        &quot;we&quot;), is responsible for the processing of your personal data
        and controls how it is used, in accordance with this Notice.
      </p>
      <p>
        At ABB, respecting your data privacy rights is a top priority. This
        Notice explains why and how we use personal data about you, how we
        process such data, and what rights you have regarding your personal
        data.
      </p>
      <p>
        <em
          ><strong
            >*2. Who is responsible for the processing of your personal
            data?*</strong
          ></em
        >
      </p>
      <p>
        ABB Ltd and its
        <a href="https://new.abb.com/privacy-policy/subsidiaries"
          >subsidiary companies</a
        >
        are responsible for your personal data. For applicable privacy and data
        protection laws, the primary controller of your data will be the ABB
        subsidiary company which is providing services (such as event
        organization) or communicating to you.
      </p>
      <p>
        ABB Engineering (Shanghai) Ltd. No. 4528 Kangxin Highway Shanghai 201319
        China will be the &quot;Controller&quot; of your personal data - this
        company is responsible for the privacy of your data.
      </p>

      <p>
        <em
          ><strong
            >*3. The types of information we collect and use?*</strong
          ></em
        >
      </p>
      <p>
        We collect and use personal data that concerns you in connection with
        the virtual event. We may collect the following categories of personal
        data:
      </p>
      <p>
        <strong>§</strong>
        <em
          ><strong
            >*The contact information you share with us to register and
            communicate in the course of the event*</strong
          ></em
        >
        such as first and last name (including what name you indicate to appear
        on your virtual account ), business email address, business mobile phone
        number, your assistant name and email address, IP address, device
        identifier data (if applicable, for example mobile device ID/PC ID,
        tracking analytics data, cookies).
      </p>
      <p>
        <strong>§</strong>
        <em
          ><strong
            >*Additional information you provide to us, such as:*</strong
          ></em
        >
        your position, job or job title
      </p>
      <p>
        <em><strong>*4. Why we use your personal data?*</strong></em>
      </p>
      <p>
        We may use your personal data as described above for the following
        purposes:
      </p>
      <p>§ register your attendance to events and forums;</p>
      <p>§ create a personal account for you in dedicated application;</p>
      <p>
        § provide you with information and services associated with the event;
      </p>
      <p>§ provide a personal event experience;</p>
      <p>
        § facilitate the virtual event and provide you with an acceptable
        service;
      </p>
      <p>§ conduct and facilitate event satisfaction surveys;</p>
      <p>
        § create media assets (photographs, videos and recordings) to showcase
        event impressions and store those assets in our brand data base;
      </p>
      <p>
        § produce content for articles and publications about the virtual event;
        and
      </p>
      <p>
        § publish photographs taken at the virtual event on ABB channels, third
        party channels and social media channels.
      </p>
      <p>
        We collect only the personal data from you that we need for the purposes
        described above. For statistical purposes, improvement of our services
        and testing of our IT systems we use as much as reasonably possible
        anonymized data. This means that these data can no longer (in)directly
        identify you or single you out as an individual.
      </p>
      <p>
        <em
          ><strong>*5. The legal basis we rely on*</strong><strong></strong>*
          *<strong>**</strong>* ***</em
        >We process your personal data for the purposes described in this notice
        based on your consent.
      </p>
      <p>
        <em
          ><strong
            >*6. Parties we share your personal data with (in and outside the EU
            and EEA or outside the country where the ABB company that controls
            your data is located)*</strong
          ></em
        >
      </p>
      <p>
        We only share your personal data with other ABB affiliates or third
        parties as necessary for the purposes described in the table below.
        Where we share your personal data with an affiliate or third party so
        that it is transferred to or becomes accessible from outside the
        European Union (“EU”) and the European Economic Area (&quot;EEA&quot;)
        or outside the country where the ABB company that controls your data is
        located, we always put adequate safeguards in place to protect your
        personal data. Examples of these safeguards are an adequacy decision of
        the European Commission (<a
          href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en"
          >read more here</a
        >), Standard Contractual Clauses (<a
          href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en"
          >read more here</a
        >), Privacy Shield certification (<a
          href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/eu-us-privacy-shield_en"
          >read more here</a
        >), and the Binding Corporate Rules that some of our services providers
        have adopted (<a
          href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/binding-corporate-rules_en"
          >read more here</a
        >). We have taken additional measures for the transfer of data from
        within to outside the EU, EEA and outside the country where the ABB
        company that controls your data is located to protect your personal
        data. If you would like an overview of the safeguards which are in
        place, please submit a request at
        <a href="http://www.abb.com/privacy">www.abb.com/privacy</a>.
      </p>
      <figure>
        <table>
          <thead>
            <tr>
              <th>
                <em
                  ><strong
                    >*Recipient name or – for non-EU countries – recipient
                    category*</strong
                  ></em
                >
              </th>
              <th>
                <em><strong>*Recipient location*</strong></em>
              </th>
              <th>
                <em><strong>*Purpose*</strong></em>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>ABB affiliates and subsidiaries</td>
              <td>
                See the
                <a href="https://new.abb.com/privacy-policy/subsidiaries"
                  >list of ABB subsidiaries</a
                >
              </td>
              <td>The purposes described in this privacy notice</td>
            </tr>
            <tr>
              <td>ABB business partners, distributors and agents</td>
              <td>EU/EEA and non-EU/EEA (global)</td>
              <td>The purposes described in this privacy notice</td>
            </tr>
            <tr>
              <td>Service providers</td>
              <td>EU/EEA and non-EU/EEA (global)</td>
              <td>
                IT services, marketing agencies, payment processors, customer
                support providers working on ABB’s behalf
              </td>
            </tr>
            <tr>
              <td>Potential or actual acquirers of ABB businesses or assets</td>
              <td>EU/EEA and non-EU/EEA (global)</td>
              <td>
                For the evaluation of the business or assets in question or for
                the purposes described in this privacy notice
              </td>
            </tr>
            <tr>
              <td>
                Recipients as required by applicable law or legal process, to
                law enforcement or government authorities, etc.
              </td>
              <td>EU/EEA and non-EU/EEA (global)</td>
              <td>
                Where required by applicable law or a legitimate request by
                government authorities, or a valid legal requirement
              </td>
            </tr>
          </tbody>
        </table>
      </figure>
      <p>
        <em><strong>*7. How long we keep your personal data*</strong></em>
      </p>
      <p>
        Based on mandatory legislation, ABB must keep certain personal data for
        a minimum period of time. We only keep your personal data for as long as
        necessary for the purposes described in this privacy notice. In general,
        personal data is kept as long as necessary for the purpose – conducting
        an event. Your personal data might be kept for longer period only if
        required by local laws and regulatory requirements. At the same time,
        applicable data protection laws require that we do not keep personal
        data in an identifiable form for any longer than is necessary for the
        purpose for which the personal data is being processed. Through the
        setting of IT applications and policies we ensure that your personal
        data is deleted when we no longer need it.
      </p>
      <p>
        For more information regarding specific retention periods that apply to
        your personal data, please submit a request at
        <a href="http://www.abb.com/privacy">www.abb.com/privacy</a>.
      </p>
      <p>
        <em><strong>*8. Your data privacy rights*</strong></em>
      </p>
      <p>
        Depending on the jurisdiction in which you are located and in which your
        personal data is processed, you may have the following rights:
      </p>
      <figure>
        <table>
          <thead>
            <tr>
              <th>
                <em><strong>*Data privacy rights*</strong></em>
              </th>
              <th>
                <em><strong>*What it means*</strong></em>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>The right to access your data</td>
              <td>
                You are entitled to ask ABB for an overview of or to obtain a
                copy of the personal data we hold about you.
              </td>
            </tr>
            <tr>
              <td>The right to have your data corrected</td>
              <td>
                You may request immediate correction of inaccurate or incomplete
                personal data we hold about you.
              </td>
            </tr>
            <tr>
              <td>The right to have your data erased</td>
              <td>
                You may request that personal data be erased when it is no
                longer needed, where applicable law obliges us to delete the
                data or the processing of it is unlawful.
              </td>
            </tr>
            <tr>
              <td>The right to restrict data processing</td>
              <td>
                You have the right to restrict the processing of your personal
                data in specific circumstances.
              </td>
            </tr>
            <tr>
              <td>The right to data portability</td>
              <td>
                You have the right to receive your personal data in a
                structured, machine-readable format for your own purposes, or to
                request us to share it with a third party.
              </td>
            </tr>
            <tr>
              <td>The right to object to the data processing</td>
              <td>
                You have the right to object to our processing of your personal
                data where we rely on our legitimate interests as the basis for
                our processing, where your data privacy rights outweigh our
                reasoning for legitimate interests.
              </td>
            </tr>
          </tbody>
        </table>
      </figure>
      <p>
        Please note that the rights described above are not absolute, and that
        your request cannot always be met entirely. For example, sometimes we
        cannot delete or restrict the processing of your personal data as we may
        have legal obligations or contractual obligations to keep certain such
        personal data.
      </p>
      <p>
        You may request to enforce your data privacy rights at
        <a href="http://www.abb.com/privacy">www.abb.com/privacy</a>.
      </p>
      <p>
        <em><strong>*Contact and further information*</strong></em>
      </p>
      <p>
        If you want to access your personal data, make use of any of your other
        rights mentioned above or if you have any questions or concerns about
        how ABB processes your personal data, please contact our Group Data
        Protection Officer at
        <a href="mailto:privacy@abb.com" target="_blank" class="url"
          >privacy@abb.com</a
        >, or submit your complaint at
        <a href="http://www.abb.com/privacy" target="_blank" class="url"
          >www.abb.com/privacy</a
        >.
      </p>
      <p>
        Should you not be satisfied with our response or believe we are
        processing your personal data against the law, you may also have the
        right to file a complaint with the Data Privacy Authority in your
        country of residence or work, or seek a remedy through the courts where
        you believe an infringement of data privacy laws may have taken place.
      </p>

      <p>
        <em
          ><strong
            >*If you consent to us using your personal data for the purposes
            described in this notice, please tick the box below. You may revoke
            your consent at any time by completing the form at
            <a href="http://www.abb.com/privacy" target="_blank" class="url"
              >www.abb.com/privacy</a
            >.*</strong
          ></em
        >
      </p>

      <p>
        agree to the processing of my personal data in relation to the
        registration for the CIIF 2020 &amp; Digital show room event.****
      </p>
    </div>

    <div class="flex flex-c flex-a">
      <div class="btn-back" @click="$router.go(-1)">
        <span class="ico-back"></span>
        {{ $t("article.fanHui") }}
      </div>
    </div>
  </div>
</template>

<script>
import { lang } from "@/utils/utils";

export default {
  name: "Xieyi",
  data() {
    return {
      lang: lang,
    };
  },
};
</script>

<style scoped>
hr {
  height: 2px;
  padding: 0;
  margin: 16px 0;
  background-color: #e7e7e7;
  border: 0 none;
  overflow: hidden;
  box-sizing: content-box;
}

li p.first {
  display: inline-block;
}

ul,
ol {
  padding-left: 30px;
}

ul:first-child,
ol:first-child {
  margin-top: 0;
}

ul:last-child,
ol:last-child {
  margin-bottom: 0;
}

blockquote {
  border-left: 4px solid #dfe2e5;
  padding: 0 15px;
  color: #777777;
}

blockquote blockquote {
  padding-right: 0;
}

table {
  padding: 0;
  word-break: initial;
}

table tr {
  border: 1px solid #dfe2e5;
  margin: 0;
  padding: 0;
}

table tr:nth-child(2n),
thead {
  background-color: #f8f8f8;
}

table th {
  font-weight: bold;
  border: 1px solid #dfe2e5;
  border-bottom: 0;
  margin: 0;
  padding: 6px 13px;
}

table td {
  border: 1px solid #dfe2e5;
  margin: 0;
  padding: 6px 13px;
}

table th:first-child,
table td:first-child {
  margin-top: 0;
}

table th:last-child,
table td:last-child {
  margin-bottom: 0;
}

.CodeMirror-lines {
  padding-left: 4px;
}

.code-tooltip {
  box-shadow: 0 1px 1px 0 rgba(0, 28, 36, 0.3);
  border-top: 1px solid #eef2f2;
}

.md-fences,
code,
tt {
  border: 1px solid #e7eaed;
  background-color: #f8f8f8;
  border-radius: 3px;
  padding: 0;
  padding: 2px 4px 0px 4px;
  font-size: 0.9em;
}

code {
  background-color: #f3f4f4;
  padding: 0 2px 0 2px;
}

.md-fences {
  margin-bottom: 15px;
  margin-top: 15px;
  padding-top: 8px;
  padding-bottom: 6px;
}

.md-task-list-item > input {
  margin-left: -1.3em;
}

.page {
  padding: 50px;
}

strong {
  font-weight: bolder;
}

p {
  line-height: 34px;
}

.btn-back {
  cursor: pointer;
  width: 120px;
  height: 42px;
  background: #ff000f;
  border-radius: 21px;
  line-height: 42px;
  margin-left: 220px;
  font-size: 20px;
  text-align: center;
  font-family: ABB;
  font-weight: 400;
  color: #ffffff;
}

.ico-back {
  width: 20px;
  height: 20px;
  background: url("../assets/img/ico-back.png") no-repeat;
  background-size: 20px 20px;
  position: relative;
  top: 3px;
}
</style>
